import { gql } from "@apollo/client";
const GET_COUPON_CODE = gql`
query GET_COUPON_CODE( $id: ID! ) {
  coupon(id: $id, idType: CODE) {
    code
    dateExpiry
    description
    amount
    discountType
  }
}
`;
export default GET_COUPON_CODE;

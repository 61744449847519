import React, { useState, useContext, useEffect } from "react";
import { Link, navigate } from 'gatsby';
import { isEmpty } from "lodash";
import { useMutation, useQuery } from "@apollo/client";
import {loadStripe} from '@stripe/stripe-js';
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import GET_CART from "../../../queries/get-cart";
import APPLY_COUPON from "../../../mutations/apply-coupon";
import CLEAR_COUPON from "../../../mutations/clear-coupon";


import { AppContext } from "../../context/AppContext";

import validateAndSanitizeCheckoutForm from "../../../validator/checkout";
import { getFormattedCart, createCheckoutData } from "../../../utils/functions";
import { isUserLoggedIn } from "../../../utils/functions";

import EnterCoupon from "../enter-coupon";
import YourOrder from "../your-order";
import Billing from "../billing";
import CreateAccount from "../create-account";
import Shipping from "../shipping";
import CheckoutError from "../checkout-error";
import Error from "./../error";



const StripeForm = (props) => {
  const [gdprChecked, setGdprChecked] = useState(false);
  const [gdpr2Checked, setGdpr2Checked] = useState(false);
  let orderData = props;
  let currency = orderData.currency;
  //console.log(orderData)

  let mod = createCheckoutData(orderData.order);


  localStorage.setItem("checkoutMod", JSON.stringify(mod)); // Store mod in local storage

  // Coupon mode functions
  const [freeShip, { data: couponResponse, loading: couponLoading, error }] = useMutation(APPLY_COUPON, {
  variables: {
    input: {
      code: "free-ship", // Or your desired coupon code
      clientMutationId: mod.clientMutationId, // Generate a unique ID
    },
  },
  skip: true,
  onCompleted: () => {
    //console.warn( 'completed APPLY_COUPON' );
    applyCoup()
  },
  onError: (error) => {
      //console.log(error.graphQLErrors[0]);
   }
  });




  /* Coupon mode function for clearing coupons */
  const [clearCouponMod, { data: clearCouponResponse, loading: clearCouponLoading, clearCouponErr }] = useMutation(CLEAR_COUPON, {
    variables: {
      input: {
        clientMutationId: mod.clientMutationId, // Generate a unique ID
        codes: ["free-ship"] // You can pass the specific coupon code if needed
      },
    },
    skip: true,
    onCompleted: () => {
      //console.warn('completed CLEAR_COUPON');
      applyCoup()
    },
    onError: (clearCouponErr) => {
      //console.log(clearCouponErr.graphQLErrors[0]);
    },
  });

  //apply free shipping coupon with mutation on orders over 200zł

  // console.log(orderData.currency, orderData.cart.totalProductsPriceEu)
  // console.log(Number(orderData.cart.totalProductsPrice.replace('zł','')))
  function applyCoup(){
    if((Number(orderData.cart.totalProductsPrice.replace('zł','')) >= 200)&&(orderData.currency==='pln')){
      try{
    	  freeShip();
    	}
  	  catch(error) {
  			console.log(error)
  		}
    } else if((Number(orderData.cart.totalProductsPriceEu.replace('€','')) >= 100)
      &&(orderData.currency==='eur')) {
      try{
    	  freeShip();
    	}
  	  catch(error) {
  			console.log(error)
  		}
    } else {
      try{
    	  clearCouponMod();
    	}
  	  catch(error) {
  			console.log(error)
  		}
    }
  }

  applyCoup();
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleOnChange = (event) => {
    if (event.target.name === 'gdpr') {
      setGdprChecked(event.target.checked);
    } else if (event.target.name === 'gdpr2') {
      setGdpr2Checked(event.target.checked);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
     // Stripe.js hasn't yet loaded.
     // Make sure to disable form submission until Stripe.js has loaded.
     return;
   }

   // Check if GDPR checkboxes are selected
    if (!gdprChecked || !gdpr2Checked) {
      setErrorMessage('Please accept the Terms and Conditions and Privacy Policy.');
      return;
    }

   setIsLoading(true);

   const isFormValid = props.handleFormSubmit();

   if (!isFormValid) {
    setIsLoading(false);
    return;
   }

    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }
    //add double click restricment
    document.getElementById('pay-btn').disabled = true;
    //console.log(typeof currency, currency);
    let stripeCurrency = String(currency);
    //console.log(typeof stripeCurrency, stripeCurrency)
    try {
      const response = await fetch('https://shop.shroom4you.com/.netlify/functions/createPaymentIntent', {
        method: 'POST',
        //headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          amountString: `${currency === 'pln' ? orderData.cart.totalProductsPrice : orderData.cart.totalEuWithShipping}`,
          currency: stripeCurrency, // Make sure stripeCurrency is a string,
        }),
        //mode: 'no-cors' // Assuming cross-origin requests
      }).then(response=>response.json()).then(async data=>{
        let clientSecret = data.clientSecret;
        stripe.confirmPayment({
         //`Elements` instance that was used to create the Payment Element
         elements,
         clientSecret,
         confirmParams: {
           return_url: 'https://shop.shroom4you.com/order-success/',
         },
        });
      })
      setIsLoading(false);
    } catch (error) {
      // console.error('Error fetching clientSecret:', error);
      setIsLoading(false);
      // Handle errors gracefully, e.g., display user-friendly messages
    }
  };



  async function loadBlik(){
    // add mod to check if blik is selected
    if(document.getElementById('b-check').checked === true){
      document.getElementById('timer').textContent = `02:00`;
      document.getElementById('b-cwrap').style.display = 'flex';
      document.getElementById('b-timer').style.display = 'block';
      document.getElementById('p-element').style.display = 'none';
      document.getElementById('pay-btn').style.display = 'none';
      document.getElementById('pay-btn-blik').style.display = 'block';
      document.getElementById('currency').disabled = true;

      let timeLeft = 2 * 60; // 2 minutes in seconds
          let interval = setInterval(function() {
              let minutes = Math.floor(timeLeft / 60);
              let seconds = timeLeft % 60;
              minutes = minutes.toString().padStart(2, '0');
              seconds = seconds.toString().padStart(2, '0');
              if (document.getElementById('timer') === null) {
                clearInterval(interval);
              } else {
                document.getElementById('timer').textContent = `${minutes}:${seconds}`;
              }
              timeLeft--;
              if (timeLeft < 0) {
                  clearInterval(interval);
                  document.getElementById('timer').textContent = "0:00";
                  document.getElementById('b-cwrap').style.display = 'none';
                  document.getElementById('b-timer').style.display = 'none';
                  document.getElementById('p-element').style.display = 'block';
                  document.getElementById('pay-btn').style.display = 'block';
                  document.getElementById('pay-btn-blik').style.display = 'none';
                  document.getElementById('currency').disabled = false;
                  document.getElementById('b-check').checked = false;
              } else if (document.getElementById('b-check') === null ) {
                clearInterval(interval);
              } else if(document.getElementById('b-check').checked === false){
                clearInterval(interval);
              }
          }, 1000);
    } else {
      document.getElementById('b-cwrap').style.display = 'none';
      document.getElementById('b-timer').style.display = 'none';
      document.getElementById('p-element').style.display = 'block';
      document.getElementById('pay-btn').style.display = 'block';
      document.getElementById('pay-btn-blik').style.display = 'none';
      document.getElementById('currency').disabled = false;
    }

  }

  const handleBlikSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
     // Stripe.js hasn't yet loaded.
     // Make sure to disable form submission until Stripe.js has loaded.
     return;
   }

   // Check if GDPR checkboxes are selected
    if (!gdprChecked || !gdpr2Checked) {
      setErrorMessage('Please accept the Terms and Conditions and Privacy Policy.');
      return;
    }

   setIsLoading(true);

   const isFormValid = props.handleFormSubmit();

   if (!isFormValid) {
    setIsLoading(false);
    return;
   }


    //add double click restricment
    document.getElementById('pay-btn-blik').disabled = true;
    //console.log(typeof currency, currency);
    const code = document.getElementById('b-code').value;
    try {
      const response = await fetch('https://shop.shroom4you.com/.netlify/functions/createBlikPaymentIntent', {
        method: 'POST',
        //headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          amountString: orderData.cart.totalProductsPrice,
        }),
        //mode: 'no-cors' // Assuming cross-origin requests
      }).then(response=>response.json()).then(async data=>{
        let clientSecret = data.clientSecret;
          const {error} = await stripe.confirmBlikPayment(data.clientSecret, {
            payment_method: {
              blik: {},
            },
            payment_method_options: {
              blik: {
                code,
              },
            },
          });

          if (error) {
            // Show error to your customer.
            //console.log(error.message);
            if (error.message === "You passed an empty string for 'payment_method_options[blik][code]'. We assume empty values are an attempt to unset a parameter; however 'payment_method_options[blik][code]' cannot be unset. You should remove 'payment_method_options[blik][code]' from your request or supply a non-empty value."){
              setErrorMessage('Please enter a 6 digit BLIK code.');
            } else if(error.message === "The parameter `payment_method_options[blik][code]` must have exactly 6 digits."){
              setErrorMessage('The BLIK code must have exactly 6 digits.');
            }
            document.getElementById('pay-btn-blik').disabled = false;
            return;
          }
          // Otherwise the customer will be redirected away to the order-success page
          else {
            navigate(`/order-success/?payment_intent_client_secret=${data.clientSecret}&payment_method=blik`);
          }
      })
      setIsLoading(false);
    } catch (error) {
    //  console.error('Error fetching clientSecret:', error);
      setIsLoading(false);
      // Handle errors gracefully, e.g., display user-friendly messages
    }

  }

  return (
    <>


    {/*BLIK PAYMENT OPTION*/}
      <label className="blik-tab card my-2">
        <div className="row" style={{display:'flex',cursor:'pointer',flexWrap:'wrap',alignItems:'center'}}>
          <div className="mr-4 col-3" style={{display:'flex',alignItems:'center',paddingLeft:'10px'}}>
            <img src="https://admin.shroom4you.com/wp-content/uploads/2023/11/blik.svg"
            style={{margin:'0 10px'}}
            width="16px"
            height="auto"
            title="Pay with BLIK"
            alt="BLIK"
            />
            <span>BLIK</span>
            <input onChange={ loadBlik } className="form-check-input"
            name="BLIK" id="b-check" type="checkbox"
            />
          </div>
          {/*BLIK CODE*/}
          <label id="b-cwrap" className="col-6" style={{display:'none',flexWrap:'wrap',alignItems:'center',justifyContent:'space-between'}}>
             Enter code:&nbsp;&nbsp;&nbsp;&nbsp;
             <input name="b-code"
             id="b-code"
             style={{maxWidth:'110px'}}
             className="form-control woo-next-checkout-input"
             type="number" placeholder="000000" required />
          </label>
        </div>
          {/*BLIK Timer*/}
          <p id="b-timer" className="mx-4 my-2" style={{display:'none',color:'#444444',paddingBottom:'10px'}}>
          You have <b id="timer">02:00</b> minutes to finish the payment
          and provide the <b>BLIK code</b> from your banking app.</p>
      </label>
      <div id="p-element" className="my-4">
      <PaymentElement />
      </div>
      <div className="form-check mt-3">
      <label>
      <input required={true} onChange={ handleOnChange } value="yes" className="form-check-input" name="gdpr" type="checkbox"/>
      <span>I have read and accepted <a href="/store-regulations/">Terms and Conditions
      <abbr className="required" title="required">
        *
      </abbr></a>.</span></label>
      </div>
      {/*	GDPR Compiliant*/}
      <div className="form-check mt-2">
      <label>
      <input required={true} onChange={ handleOnChange } value="yes" className="form-check-input" name="gdpr2" type="checkbox"/>
      <span>I declare that I have read and accepted the <a href="/privacy-policy/">Privacy Policy</a> and information about the processing of my personal data by the Data Administrator, Shroom sp. z o.o. based in Olsztyn, Towarowa 20b, 10-417 Olsztyn.<abbr className="required" title="required">
        *
      </abbr></span>
      </label>
      </div>
      {errorMessage && <div style={{color:'#dc3545'}}>{errorMessage}</div>}
      <div className="woo-next-place-order-btn-wrap mt-2">
        <button
          disabled={!stripe || !elements}
          className="btn button btn-lg btn-block"
          type="submit"
          id="pay-btn"
          onClick={handleSubmit}
        >
          Place Order & Pay
        </button>
        <button
          disabled={!stripe || !elements}
          className="btn button btn-lg btn-block"
          type="submit"
          id="pay-btn-blik"
          style={{display:'none'}}
          onClick={handleBlikSubmit}
        >
          Place Order & Pay - BLIK
        </button>
      </div>

    </>
  );
};







const CheckoutForm = () => {

  const [cart, setCart] = useContext(AppContext);
  const [orderData, setorderData] = useState(null);
  const [requestError, setRequestError] = useState(null);

  const [selectedCurrency, setSelectedCurrency] = useState('pln');

  const auth = isUserLoggedIn();
  const [shipVisibility, setShipVisibility] = useState( false );

  // Get Cart Data.
  const { data, refetch } = useQuery(GET_CART, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      // console.warn( 'completed GET_CART' );
      // Update cart in the localStorage.
      const updatedCart = getFormattedCart(data);
      localStorage.setItem("woo-next-cart", JSON.stringify(updatedCart));
      // Update cart data in React Context.
      //console.log(JSON.stringify({`${selectedCurrency === 'pln' ? cart.totalProductsPrice : cart.totalProductsPriceEu}`,selectedCurrency.toString()}));
      setCart(updatedCart);
    },
  });

  //console.log(cart)

  let initialState = {
    firstName: '',
    lastName: '',
    company: '',
    country: 'PL',
    address1: '',
    address2: '',
    city: '',
    state: '',
    postcode: '',
    phone: '',
    email: '',
    createAccount: false,
    shipToDifferentAddress: false,
    username: '',
    password: '',
    customerNote: '',
    isPaid: false,
    paymentMethod: 'stripe',
    errors: null,
    currency:'pln',
  };

  const [input, setInput] = useState(initialState);


  const stripePromise = loadStripe(process.env.PUBLIC_STRIPE_PUBLISHABLE_KEY);
  let cartVal;

  // TODO: EURO MOD

  if((cart !== null)&&(cart.totalProductsPrice !== undefined)){
  cartVal = Number(cart.totalProductsPrice.replace('zł','').replace('.',''));
  } else {
    cartVal = 10000
  }
  //console.log(selectedCurrency)
  const options = {
    mode: 'payment',
     amount: cartVal,
     currency: selectedCurrency,
    // Fully customizable with appearance API.
    appearance: {
      theme: 'stripe',
      variables: {
        fontWeightNormal: '500',
        borderRadius: '2px',
        colorPrimary: '#f7adb6',
        tabIconSelectedColor: '#fff',
        gridRowSpacing: '16px'
      },
    rules: {
      '.Tab, .Input, .Block, .CheckboxInput, .CodeInput': {
        boxShadow: '0px 3px 10px rgba(18, 42, 66, 0.08)'
      },
      '.Block': {
        borderColor: 'transparent'
      },
      '.BlockDivider': {
        backgroundColor: '#ebebeb'
      },
      '.Tab, .Tab:hover, .Tab:focus': {
        border: '0'
      },
      '.Tab--selected, .Tab--selected:hover': {
        backgroundColor: '#f7adb6',
        color: '#fff'
      }
    }
    },
  };

  /*
   * Handle form submit.
   *
   * @param {Object} event Event Object.
   *
   * @return {void}
   */
   const handleFormSubmit = (event) => {
     // event.preventDefault()
     const result = validateAndSanitizeCheckoutForm(input);

     if (!result.isValid) {
       setInput({ ...input, errors: result.errors });
       return false;
     } else {
       setRequestError(null);
       return true;
     }
   };
  /*
   * Handle onchange input.
   *
   * @param {Object} event Event Object.
   *
   * @return {void}
   * prbolem with different adress method - fixed
   */
  const handleOnChange = (event) => {
    if ("shipToDifferentAddress" === event.target.name) {
      const newState = { ...input, [event.target.name]: !input.shipToDifferentAddress };
      refetch()
      setInput(newState);
    }  else if ("createAccount" === event.target.name) {
      const newState = { ...input, [event.target.name]: !input.createAccount };
      setInput(newState);
    } else if ("country" === event.target.name || "country2" === event.target.name) {
      const newState = { ...input, [event.target.name]: event.target.value };
      refetch()
      setInput(newState);
    } else if ("currency" === event.target.name) {
      const newState = { ...input, [event.target.name]: event.target.value };
      if(event.target.value === 'pln'){
        document.getElementById('b-check').disabled = false;
        document.getElementsByClassName('blik-tab')[0].style.display = 'block';
      } else {
        document.getElementById('b-check').disabled = true;
        document.getElementsByClassName('blik-tab')[0].style.display = 'none';
      }
      // refetch()
      setInput(newState);
    } else {
      const newState = { ...input, [event.target.name]: event.target.value };
      setInput(newState);
    }

  };




  const switchCurrency = (event) => {
    handleOnChange(event);
    setSelectedCurrency(event.target.value);
  };

  return (
    <>
      {cart ? (
        <form onSubmit={handleFormSubmit} className="woo-next-checkout-form">
          <div className="row">
            {/*Billing Details*/}
            <div className="col-lg-6 mb-lg-0 mb-5">
              <h2 className="mb-4">Billing Details</h2>
              <Billing input={input} handleOnChange={handleOnChange} />
              { isEmpty( auth ) ? <CreateAccount handleOnChange={ handleOnChange } input={ input }/> : null }
              {/* Additional shipping mod */}
        				<div className="form-check mb-2 mt-2">
        					<label className="form-check-label">
        						<input
        							onChange={ handleOnChange }
        							onClick={ () => setShipVisibility( ! shipVisibility ) }
        							className="form-check-input"
        							name="shipToDifferentAddress"
        							type="checkbox"
        						/>
        						Deliver to a different address?
        					</label>
        		    </div>
                { shipVisibility ? (
        					<Shipping input={input} handleOnChange={handleOnChange} />
        					) : null
        			  }
              <div className="form-group">
                <label htmlFor="order-notes">Order Notes</label>
                <textarea onChange={ handleOnChange } defaultValue={ input.customerNote } name="customerNote" className="form-control woo-next-checkout-textarea" id="order-notes" rows="4"/>
                <Error errors={ input.errors } fieldName={ 'customerNote' }/>
              </div>

            </div>


            {/* Order & Payments */}
            <div className="col-lg-6">
              {/*	Order*/}
              <h2 className="mb-4">Your Order</h2>

              {/*console.log(input, cart)*/}
              <EnterCoupon coupons={cart} code={null} refetch={refetch} mutid={input.clientMutationId} />

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>Currency:</label>
                </div>
                <div className="form-group col-md-6" style={{textAlign:'right'}}>
                <select id="currency" name="currency" onInput={switchCurrency} onChange={handleOnChange} value={selectedCurrency}>
                  <option value="eur">EUR</option>
                  <option value="pln">PLN</option>
                </select>
                </div>
              </div>

              <YourOrder currency={selectedCurrency} cart={cart} />
              <br />
              {/* Payment + stripe */}
              <Elements stripe={stripePromise} options={options}>
                <StripeForm handleFormSubmit={handleFormSubmit} order={input} currency={selectedCurrency} refetch={refetch} cart={cart} />
              </Elements>
              {requestError && <CheckoutError requestError={ requestError }/> }
            </div>
          </div>
        </form>
      ) :  (
         <>
         <p style={{textAlign:'center'}}>There are no items in your cart <Link className="text-primary" to='/'>return to shop </Link>, then add some stuff.</p>
         <div className="container mt-5" style={{height: '72vh',textAlign:'center'}}>
           <h2>No items in your cart</h2>
           <p>Please, add some products to your cart.</p>
           <Link to="/">
             <button className="btn btn-primary woo-next-large-black-btn">
               <span className="woo-next-cart-checkout-txt">
                 Return to shop
               </span>
               <i className="fas fa-long-arrow-alt-right" />
             </button>
           </Link>
        </div>
        </>
       )}
    </>
  );
};

export default CheckoutForm;

import React, { useEffect } from "react"
import Layout from "../components/layout";
import CheckoutForm from "../components/checkout/checkout-form";
import SEO from "../components/seo";

const Checkout = () => {
  useEffect(() => {
  document.getElementById('lang-switch').href="/pl/zamowienie/"
  }, []);
  return (
  <>
  <SEO
		title={ 'Buy shroom drinks!' }
		description={''}
		header={ { siteTitle: ' Shroom | Checkout' } }
	/>
  <Layout>
    <div className="container my-5">
      <h1 className="mt-5 mb-4 spec-h" style={{textAlign:'center'}}>Checkout Page</h1>
      <CheckoutForm />
    </div>
  </Layout>
  </>
)};

export default Checkout;

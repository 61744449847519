import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import GET_COUPON_CODE from "../../../queries/get-coupon-code";
import APPLY_COUPON from "../../../mutations/apply-coupon";
import { getFormattedDate } from "../../../utils/functions";
//import Error from "../error";



const EnterCoupon = ( props ) => {


	let refreshCart = props.refetch;
	const [formVisibility, setFormVisibility] = useState(false);
	// Get Coupons Data.
	const { data, error, loading, refetch } = useQuery(GET_COUPON_CODE, {
		variables: {
			id: '',
		},
		skip: true,
		onError: (error) => {
      console.error('Error while fetching coupon data:', error);
			// document.getElementById('wrong-code').style.display = 'block';
    },
	});

	// Coupon mode function
	const [couponMod, { data: couponResponse, loading: couponLoading, err }] = useMutation(APPLY_COUPON, {
	variables: {
		input: {
			code: '', // Or your desired coupon code
			clientMutationId: props.mutid, // Generate a unique ID
		},
	},
	skip: true,
	onCompleted: () => {
		// console.warn( 'completed APPLY_COUPON' );
		refetch();
		refreshCart();
	},
	onError: (err) => {
			console.log(err.graphQLErrors[0]);
			document.getElementById('wrong-code').style.display = 'block';
			document.getElementById('wrong-code').innerHTML = JSON.stringify(err.graphQLErrors[0].message);
	 }
	});


	async function applyCoupon(event) {
  event.preventDefault();
  const couponCode = document.getElementById('code').value;
	//console.log(couponCode)
  // Refetch the query with the updated couponCode
	try{
	  await refetch({
	    id: couponCode,
	  }).then((response)=>{
			// Display couponData or handle loading and error states
			document.getElementById('wrong-code').style.display = 'none';
			document.getElementById('is-coupon').style.display = 'block';
			document.getElementById('coupon-mod').innerHTML = 'Your discount of -' +
			response.data.coupon.amount + ' ' + response.data.coupon.discountType + ' have been applied.';
		 	// console.log(response.data);
			//add the coupon to the cart using mutation
			// Use the coupon code obtained from the query result in the mutation
	    couponMod({
	      variables: {
	        input: {
	          code: response.data.coupon.code, // Use the obtained coupon code
	          clientMutationId: props.mutid,
		        },
		      },
		    });
		})
	}
	  catch(error) {
			console.log(error);
			document.getElementById('is-coupon').style.display = 'none';
			document.getElementById('wrong-code').style.display = 'block';
		}
	}


	return (
		<>
		<div className="form-check">
			<label className="form-check-label">
				<input
					onClick={ () => setFormVisibility( ! formVisibility ) }
					className="form-check-input"
					name="enterCoupon"
					type="checkbox"
				/>
				Got a <b>coupon code</b>?
			</label>
			<br />
			{
				formVisibility ? (
					<>
					<br />
					<div className="row" style={{maxWidth:'100%',alignItems:'center'}} >

						<input
							type="text"
							className="col-6 form-control woo-next-checkout-input"
							id="code"
							name="code"
							placeholder="Enter coupon code..."
						/>
						<div className="col-6" >
							<button
								onClick={applyCoupon}
								className="btn button"
							>
								Apply code
							</button>
						</div>
					</div>
				</>
			) : null
			}
			<br />
			<p id="wrong-code" style={{display:'none'}} className="text-danger">
			Wrong coupon code...</p>
		</div>
		<p id="is-coupon" style={{display:'none'}} className="text-success">
			Congratulations! Your coupon is valid!<br />
			<span id="coupon-mod"></span>
		</p>
		<br />
		</>
	)

};

export default EnterCoupon;

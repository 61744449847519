import { gql } from "@apollo/client";

const CLEAR_COUPON = gql`
  mutation ClearCoupon($input: RemoveCouponsInput!) {
    removeCoupons(input: $input) {
      cart {
        appliedCoupons {
          edges {
            node {
              code
            }
          }
        }
      }
    }
  }
`;

export default CLEAR_COUPON;




const countryList = [
	{ countryCode: "AT", countryName: "Austria" },
	{ countryCode: "CZ", countryName: "Czech Republic" },
	{ countryCode: "DK", countryName: "Denmark" },
	{ countryCode: "DE", countryName: "Germany" },
	{ countryCode: "NL", countryName: "Netherlands" },
	{ countryCode: "PL", countryName: "Poland" },
	{ countryCode: "SK", countryName: "Slovakia" },
	// { countryCode: "SE", countryName: "Szwecja" },
];

export default countryList;

import { gql } from "@apollo/client";

const APPLY_COUPON = gql`
mutation APPLY_COUPON($input: ApplyCouponInput!) {
  applyCoupon(input: $input) {
    cart {
      appliedCoupons {
        edges {
          node {
            code
          }
        }
      }
    }
  }
}`;

export default APPLY_COUPON;
